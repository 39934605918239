<template>
  <div>
    <div class="white">
      <v-container class="w-1250">
        <v-row no-gutters>
          <v-col
            sm="12"
          >
            <v-img
              v-if="question.image"
              :src="$getFullPath(question.image)"
              class="mt-12 mb-6 mx-auto d-block w-100"
              style="max-width: 600px;"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div
              class="text-center question_title"
              v-html="question.title"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="w-1250 pt-16">
      <v-row>
        <v-col
          v-for="(a, index) in question.answers"
          :key="index"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card
            class="d-flex align-center"
            :style="{ background: getColor(a), 'flex-wrap': 'wrap', 'padding': '10px' }"
            height="200"
            tile
            dark
            @click="checkAnswer(a)"
          >
            <div
              class="display-1 text-center text--white"
              style="flex-basis: 100%; height: 0; font-weight: bold;"
            >
              {{ answer_labels[index] }}
            </div>
            <div
              class="display-1 flex-grow-1 text-center text--white answer_title"
            >
              <div
                v-if="answer.id === a.id && answer.correct === false"
                class="wrong"
              >
                X
              </div>
              <span :style="getFont(a.title)">
                {{ a.title }}
              </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="footer">
      <v-container class="w-1250 mt-16">
        <v-row>
          <v-col align-self="end">
            <v-stepper v-model="question_id">
              <v-stepper-header>
                <template v-for="n in quiz.questions.length">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="question_id >= n"
                    :step="n"
                    color="#3ea2d1"
                    :complete-icon="n.toString()"
                  />
                </template>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainQuestions',
  data () {
    return {
      question_id: null,
      answer: {
        id: null,
        correct: null
      },
      quiz: {
        questions: []
      },
      question: {
        image: null
      },
      answer_labels: ['A', 'B', 'C', 'D', 'E', 'F', 'J']
    }
  },
  beforeMount () {
    this.question_id = parseInt(this.$route.params.question_id)
    this.getQuizInfo()
    if (localStorage.getItem('email') === null) {
      this.$store.commit('setNotification', { color: 'error', message: 'Sorry this page cannot be accessible' })
      console.log('Please define email for reward and go to main page')
    }
  },
  methods: {
    getColor (answer) {
      let color = '#522e88'
      if ((this.answer.correct === true && this.answer.id === answer.id) ||
          (this.answer.correct !== null && answer.is_correct === true)) {
        color = '#639643'
      } else if (this.answer.correct === false) {
        color = '#8a8f8e'
      }
      // (this.answer.correct === false && this.answer.id === answer.id)
      return color
    },
    getFont (str) {
      let font = ''
      if (str.length > 34 && str.length <= 40) {
        font = 'font-size: 24px'
      } else if (str.length > 40 && str.length <= 50) {
        font = 'font-size: 20px; line-height: 26px;'
      } else if (str.length > 50) {
        font = 'font-size: 16px; line-height: 26px;'
      }
      return font
    },
    checkAnswer (answer) {
      // to avoid option of changing choice
      if (this.answer.correct === null) {
        this.answer.correct = answer.is_correct === true
        this.answer.id = answer.id
        this.saveInStorage({ question_id: this.question_id, answer: this.answer.correct })
        setTimeout(() => this.reload(), 3000)
      } else {
        this.$store.commit('setNotification', { color: 'error', message: 'you can\'t change your choice' })
      }
    },
    /**
     * { question_id: int, answer: bool }
     * @param answerObj
     */
    saveInStorage (answerObj) {
      const email = localStorage.getItem('email')
      const answers = JSON.parse(localStorage.getItem('answers' + email + this.$route.params.id)) || []
      // save only unique values by question_id
      if (answers.length === 0 ||
          answers.find(item => item.question_id === this.question_id) === undefined) {
        answers.push(answerObj)
        localStorage.setItem('answers' + email + this.$route.params.id, JSON.stringify(answers))
      } else {
        const message = 'This answer will not be counted because you have already answered it, the result cannot be changed'
        this.$store.commit('setNotification', { color: 'error', message: message })
      }
    },
    reload () {
      this.$router.push({
        name: 'quiz',
        params: { id: this.$route.params.id, question_id: (this.question_id + 1) }
      })
      window.location.reload()
    },
    getQuizInfo () {
      this.$http
        .get(`/quizes/active/?id[]=${this.$route.params.id}&take=500&status=true&question_status=true`)
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (data.length !== 0 && data[0].questions.length >= this.question_id) {
            this.quiz = data[0]
            // get only question which step is
            this.question = data[0].questions[this.question_id - 1]
          } else if (data[0].questions.length === (this.question_id - 1)) {
            this.$router.push({ name: 'reward', params: { quiz_id: this.$route.params.id } })
          } else {
            this.$store.commit('setNotification', { color: 'error', message: 'Sorry this question not found or disabled' })
            this.$router.push({ name: 'home' })
          }
        })
    }
  }
}
</script>

<style>
div.v-main__wrap {
  min-height: 100vh;
  padding-bottom: 140px;
  background: #f1f3f5;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
/* stepper block */
div.v-stepper__header {
  height: 55px;
}
div.theme--light.v-stepper {
  background: rgb(112,78,143);
  background: linear-gradient(90deg, rgba(112,78,143,1) 0%, rgba(56,37,109,1) 100%);
  border-radius: 0;
}
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: #8a8f8e !important;
}
div.v-stepper__step {
  padding: 12px 20px 20px 20px;
}
.theme--light.v-stepper .v-stepper__step__step .v-icon{
  font-style: normal;
}
span.v-stepper__step__step {
  font-size: 1.25rem;
  height: 32px;
  min-width: 32px;
  width: 32px;
}
</style>
